import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api.evag.io/',
  withCredentials: true,
});

const locations = (country = null, state = null) => {
  let uri = '/locations';
  if (country) {
    uri += `/${country}`;
    if (state) {
      uri += `/${state}`;
    }
  }
  return api.get(uri);
};

const count = (group) => {
  return api.get(`/groups/${group}/count`);
};

const submit = (group, values) => {
  const common = {};
  if ('evag' in window) {
    if ('getEvlt' in window.evag) {
      common.evlt = window.evag.getEvlt();
    }

    if ('getProjectID' in window.evag) {
      common.projectID = window.evag.getProjectID();
    }
  }

  const data = Object.assign({}, common, values);
  delete data.redirect;

  return api.post(`/groups/${group}/submit`, data);
};

export {
  api,
  count,
  locations,
  submit,
};
