import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Button from './Button';
import CountSignatures from './CountSignatures';

import styles from './Hero.module.scss';

const Hero = ({background, description, group, title}) => (
  <div className={styles.container}>
    <Img className={styles.image} fluid={background} style={{position: false}} />
    <div className={styles.overlay}>
      <div className="wrap">
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
        <CountSignatures group={group} />
        <p className={styles.description}><Button to="#sign">Assine agora</Button></p>
      </div>
    </div>
  </div>
);

Hero.propTypes = {
  background: PropTypes.object,
  description: PropTypes.string,
  group: PropTypes.string,
  title: PropTypes.string,
};

export default Hero;
