import React from 'react';
import PropTypes from 'prop-types';
import {ErrorMessage} from 'formik';

import {locations} from '../../evag';

import Select from './Select';

import styles from './form.module.scss';

const ufList = ['SP', 'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'];

let cityCache = [];

class InputLocation extends React.Component {
  constructor(props) {
    super(props);

    this.reloadCities = this.reloadCities.bind(this);
    this.ufHandleChange = this.ufHandleChange.bind(this);

    this.state = {
      cityList: [],
      loadingCities: false,
    };
  }

  reloadCities(uf) {
    this.setState({
      cityList: [],
      loadingCities: true,
    });

    locations('BR', uf)
      .then(res => {
        cityCache = res.data;
        this.setState({
          cityList: cityCache,
          loadingCities: false,
        });
      })
      .catch(err => {
        console.error(err);

        this.setState({
          loadingCities: false,
        });

        this.props.setFieldValue(this.props.names[0], '');
        this.props.setFieldValue(this.props.names[1], '');
      });
  }

  componentDidMount() {
    const uf = this.props.values[this.props.names[0]];
    if (uf) {
      this.setState({cityList: cityCache});
    }
  }

  ufHandleChange(event) {
    this.props.setFieldValue(this.props.names[1], '');
    this.reloadCities(event.target.value);
    this.props.handleChange(event);
  }

  render() {
    return (
      <span style={{position: 'relative'}}>
        <Select
          className={this.props.className}
          name={this.props.names[0]}
          onBlur={this.props.handleBlur}
          onChange={this.ufHandleChange}
          options={ufList}
          placeholder="Estado"
          style={{width: '25%'}}
          value={this.props.values[this.props.names[0]]}
        />
        <Select
          className={this.props.className}
          loaderColor={this.props.loaderColor}
          loading={this.state.loadingCities}
          name={this.props.names[1]}
          onBlur={this.props.handleBlur}
          onChange={this.props.handleChange}
          options={this.state.cityList}
          placeholder="Selecione uma cidade"
          style={{marginLeft: '1%', width: '74%'}}
          value={this.props.values[this.props.names[1]]}
        />
        <ErrorMessage name={this.props.names[1]} component="div" className={styles.error} />
      </span>
    );
  }
}

InputLocation.defaultProps = {
  className: '',
  loaderColor: '#ccc',
};

InputLocation.propTypes = {
  className: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  loaderColor: PropTypes.string,
  names: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

export default InputLocation;
