import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import ScrollableAnchor from 'react-scrollable-anchor';

import ExtLink from '../components/ExtLink';
import FormSignature from '../components/FormSignature';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import SEO from '../components/SEO';

export default function render({data}) {
  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} />

      <Hero
        background={data.hero.childImageSharp.fluid}
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
        group={data.site.siteMetadata.group}
      />

      <main className="main">
        <div className="wrap">
          <p>
            <strong>Atenção, estudante!<br />
            Apoie o projeto para renegociação das dívidas com o FIES</strong>
          </p>

          <p>Está tramitando na Câmara Federal projeto de lei que propõe a renegociação das dívidas do FIES. Mobilize-se pra apoiar essa causa!</p>

          <p>Hoje, são milhares de estudantes endividados de forma injusta e insustentável. Buscando o sonho do diploma de ensino superior, nós, estudantes, somos submetidos a mensalidades abusivas e planos de pagamento que não consideram nossas atuais dificuldades de inserção no mercado de trabalho. Os donos das universidades ficam milionários, enquanto nós somos explorados.</p>

          <p>Se os mega-empresários do Brasil têm direito a planos de refinanciamento de suas dívidas com o Estado, por meio por exemplo do Refis, o mesmo deve valer para os estudantes e trabalhadores.</p>

          <p>Por isso, nós apoiamos o PL 1289/19, apresentado pela Deputada Federal Sâmia Bomfim (PSOL), em coautoria com Fernanda Melchionna e David Miranda.</p>

          <p>O PL 1289/2019 altera o art. 5º-A da Lei nº 10.260, de 12 de julho de 2001, para dispor a respeito do reparcelamento e reescalonamento de dívidas do FIES. O projeto visa <strong>perdoar parte da dívida do estudante e parcelar o restante</strong>, de modo que se possa dar continuidade ao estudo e que o FIES continue existindo sustentavelmente.</p>

          <p>
            <strong>Leia o projeto na íntegra:</strong> <ExtLink to="https://www.camara.leg.br/proposicoesWeb/fichadetramitacao?idProposicao=2193468">camara.leg.br/proposicoesWeb/fichadetramitacao?idProposicao=2193468</ExtLink>
          </p>
        </div>
      </main>

      <ScrollableAnchor id="sign">
        <FormSignature group={data.site.siteMetadata.group} />
      </ScrollableAnchor>
    </Layout>
  );
}

render.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        group
      }
    }

    hero: file(relativePath: { eq: "og-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
